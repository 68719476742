<template>
  <div>
    <TsTypography as="h2" variant="h3" align="center" append-class="mb-2 sm:mb-3">
      {{ useTranslation("customerSupport", "Customer Support") }}
  </TsTypography>
    <div class="overflow-auto no-scrollbar">
      <div
          v-if="props.data && props.data.length"
          :class="twMerge(props.supportVariant ? ' flex max-md:flex-wrap justify-center gap-5 md:gap-8 w-[30rem]' : 'flex sm:justify-center space-x-4 text-center', props.appendClass)">
        <div :class="'max-w-[106px] w-full min-w-[70px]'"
             v-for="item in props.data" :key="item">
          <NuxtLinkWithLocale :to="item.redirectUrl">
            <div class="p-[1.2px] mb-2.5 rounded-md w-[72px] h-[72px] lg:w-[108px] lg:h-[108px]"
                 :class="twMerge(!props.supportVariant ? 'bg-[linear-gradient(180deg,_#E6F3FF_0%,_#FFFFFF_100%)]  shadow-md mx-auto' : '', props.borderClass)">
              <div class="rounded-md h-full flex items-center"
                   :class="twMerge(!props.supportVariant ? 'p-4 bg-[linear-gradient(225deg,_#FFFFFF_-0.2%,_#F1F8FF_99.8%)]' : 'py-5  sm:py-7', props.bgClass)">
                <NuxtImg
                    v-if="item.image"
                    :src="item.image"
                    :alt="item.imageAltText"
                    :height="isMobile ? '36' : '52'"
                    class="mx-auto"
                    :width="isMobile ? '36' : '52'" />
              </div>
            </div>
          </NuxtLinkWithLocale>
          <NuxtLinkWithLocale v-if="item.title" :to="item.redirectUrl">
            <TsTypography align="center" :variant="textVariant"
                          :append-class="props.supportVariant ? 'mt-3' : 'mb-0 text-black'">{{ useTranslation(item.title,item.default_key) }}
            </TsTypography>
          </NuxtLinkWithLocale>
        </div>
      </div>
    </div>
  </div>

</template>


<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useRandomUUID } from "../../composables/useRandomUUID";
import { twMerge } from "tailwind-merge";

const { isMobile } = useDevice()

type Data = {
  id: number;
  title?: string;
  image?: string;
  imageAltText?: string;
  redirectUrl?: string;
  default_key?:string;
}

type Props = {
  data?: Data[];
  borderClass?: string;
  bgClass?: string;
  dataTestid?: string;
  appendClass?: string;
  variant?: string;
  imgMobileHeight?: string;
  imgDesktopHeight?: string;
  imgMobileWidth?: string;
  imgDesktopWidth?: string;
  supportVariant?: boolean;
  textVariant?: 'sm' | 'base' | 'lg';
}


//@ts-ignore
const props = withDefaults(defineProps<Props>(), {
  data: () => [
  {
    id: 1,
    image: '/images/logos/delivery1.svg',
    imageAltText: 'Get up to 50% off',
    title:'trackOrder',
    default_key:'Volg bestelling',
    redirectUrl: '/account/orders'
  },
  {
    id: 2,
    image: '/images/logos/pro2.svg',
    imageAltText: 'Get up to 50% off',
    title:'proCard',
    default_key: 'Pro-kaart',
    redirectUrl: '/pro-card'
  },
  {
    id: 3,
    image: '/images/logos/store.svg',
    imageAltText: 'Get up to 50% off',
    title:'shopNearby',
    default_key: 'Winkel in de buurt',
    redirectUrl: '/branches'
  },
  {
    id: 4,
    image: '/images/logos/bulk.svg',
    imageAltText: 'Get up to 50% off',
    title:'bulkEnquiry',
    default_key: 'Bulkaanvraag',
    redirectUrl: '/contact-us'
  }
],
textVariant: 'base'
});

const random_id = ref("");
onMounted(() => {
  random_id.value = useRandomUUID();
});

const height = isMobile ? props.imgMobileHeight || props.supportVariant ? '52' : '36' : props.imgDesktopHeight;

const width = isMobile ? props.imgMobileWidth || props.supportVariant ? '52' : '36' : props.imgDesktopWidth;

</script>
